import * as R from 'ramda'
import {arrayOf, shape} from 'prop-types'
import {graphql} from 'gatsby'
import React from 'react'

import {
  aboutUsPropTypes,
  carouselPropTypes,
  insightsPropTypes,
  joinUsPropTypes,
  manifestoPropTypes,
  pageContextPropTypes,
  playbookPropTypes,
  servicesPropTypes,
  worldwidePropTypes,
} from 'helpers/propTypes'
import {getServices} from 'helpers/services'
import AboutUs from 'components/Home/AboutUs'
import Insights from 'components/Home/Insights'
import JoinUs from 'components/Home/JoinUs'
import MainCarousel from 'components/Home/MainCarousel'
import Manifesto from 'components/Home/Manifesto'
import Playbook from 'components/Home/Playbook'
import SEO from 'components/seo'
import Services from 'components/Home/Services'
import Worldwide from 'components/Home/WorldWide'

const RootIndex = ({pageContext, data}) => {
  const countryData = R.path(['contentfulCountry'], data)
  const {technicalName, urlPrefix} = countryData
  const {
    metaTitle,
    metaDescription,
    companyPurpose,
    socialMediaLinks,
    playbook,
    careers,
    aboutUs,
    locations,
    insights,
    services,
  } = R.pathOr(null, ['allContentfulHomepage', 'edges', 0, 'node'], data)

  const {partnersSubtitlesHomepage, partnersSurtitleHomepage, partnersTitle} =
    R.pathOr(null, ['allContentfulPlaybook', 'nodes', 0], data)

  const {homepageTitle, name} = R.pathOr(null, [], services)
  const {carousel, partners} = R.pathOr(null, ['contentfulCountry'], data)
  const {allContentfulInsight} = data

  const locationsArray = R.pathOr(
    null,
    ['allContentfulLocations', 'edges', 0, 'node', 'locations'],
    data,
  )
  const continentsArray = R.pathOr(
    null,
    ['allContentfulContinent', 'edges'],
    data,
  )

  const contentfulCountry = R.pathOr(null, ['contentfulCountry'], data)
  const countryServices = R.pathOr(null, ['services'], contentfulCountry)

  const topLevelServices =
    countryServices && R.pathOr(null, ['topLevelServices'], countryServices)

  const keyPlays = R.pathOr(null, ['allContentfulKeyPlay', 'edges'], data)
  const nodeKeyPlays = R.map(({node}) => node, keyPlays)
  const siteUrl = R.pathOr(null, ['site', 'siteMetadata', 'siteUrl'], data)

  const logo = R.pathOr(null, ['contentfulHeader', 'logo'], data)

  const pageMetaData = {
    metaTitle: R.pathOr('', ['metaTitle'], metaTitle) || metaTitle,
    metaDescription: R.pathOr('', ['metaDescription'], metaDescription),
    siteMetaData: R.pathOr('', ['siteMetaData'], data),
    genericData: R.pathOr('', ['contentfulGenericData'], data),
  }

  return (
    <>
      <SEO
        pageContext={pageContext}
        countryData={countryData}
        pageMetaData={pageMetaData}
      />
      {carousel && (
        <MainCarousel
          carousel={carousel}
          socialMediaLinks={socialMediaLinks}
          webSiteName={process.env.GATSBY_WEBSITE}
        />
      )}
      <Manifesto data={companyPurpose} pageContext={pageContext} />
      {countryServices && (
        <Services
          homepageTitle={homepageTitle}
          name={name}
          services={getServices(data, topLevelServices)}
        />
      )}
      <Playbook playbook={playbook} keyPlays={nodeKeyPlays} />
      {allContentfulInsight && allContentfulInsight.edges[0] && (
        <Insights
          data={insights}
          carousel={allContentfulInsight}
          country={urlPrefix}
        />
      )}
      <JoinUs
        data={careers}
        link={`//${data.careerLink.link}`}
        logo={logo}
        hasWideAvatar
      />
      <AboutUs data={aboutUs} />
      <Worldwide
        data={locations}
        locations={locationsArray}
        continents={continentsArray}
        siteUrl={siteUrl}
        ctaColor="primary"
      />
    </>
  )
}

RootIndex.propTypes = {
  data: shape({
    allContentfulpage: shape({
      edges: arrayOf(
        shape({
          node: shape({
            mainCarousel: carouselPropTypes,
            manifesto: manifestoPropTypes,
            services: servicesPropTypes,
            playbook: playbookPropTypes,
            joinUs: joinUsPropTypes,
            aboutUs: aboutUsPropTypes,
            worldwide: worldwidePropTypes,
            insights: insightsPropTypes,
          }),
        }),
      ),
    }),
  }).isRequired,
  pageContext: pageContextPropTypes.isRequired,
}

RootIndex.defaultProps = {}

export default RootIndex

export const pageQuery = graphql`
  query templateHome(
    $entityRegEx: String
    $nodeLocale: String
    $technicalName: String
  ) {
    allContentfulHomepage(
      filter: {node_locale: {eq: $nodeLocale}, slug: {regex: $entityRegEx}}
    ) {
      edges {
        node {
          metaTitle {
            metaTitle
          }
          metaDescription {
            metaDescription
          }
          companyPurpose {
            parallaxMediaType
            callToAction
            slug
            title
            shortDescription {
              shortDescription
            }
            image {
              gatsbyImageData
            }
            video {
              file {
                url
              }
            }
            longDescription {
              raw
            }
            name
            metaTitle
            contentsSections1 {
              image {
                file {
                  url
                  contentType
                }
              }
              title
              variation
              description {
                raw
              }
            }
            tooltipHumanData {
              tooltipHumanData
            }
            tooltipInspirationalReality {
              tooltipInspirationalReality
            }
            tooltipSimplexFuture {
              tooltipSimplexFuture
            }
          }
          locations {
            ...locationsFields
          }
          services {
            homepageTitle
            name
          }
          socialMediaLinks {
            id
            name
            link
            icon {
              file {
                url
              }
            }
            iconHover {
              file {
                url
              }
            }
          }
          playbook {
            slug
            name
            metaTitle
            pageTitle
            searchPlaceholder
            shortDescription {
              shortDescription
            }
            homepageTitle
            callToAction
            metaDescription {
              metaDescription
            }
          }
          insights {
            callToAction
            node_locale
            name
            homepageTitle
          }
          careers {
            homepageTitle
            callToAction
            slug
            shortDescription {
              shortDescription
            }
            employeeTestimonyCallToAction
            employeeTestimonyName
            employeeTestimonyJobTitle
            employeeTestimonyDescription {
              employeeTestimonyDescription
            }
            employeeTestimonyImage {
              gatsbyImageData(placeholder: BLURRED)
            }
            name
            workingAtKeyrusImage {
              gatsbyImageData(placeholder: BLURRED)
              description
            }
            workingAtKeyrusDescription {
              workingAtKeyrusDescription
            }
            workingAtKeyrusTitle
          }
          aboutUs {
            id
            callToAction
            slug
            shortDescription {
              shortDescription
            }
            pillarsTitle
            pillarsItem5
            pillarsItem4
            pillarsItem3
            pillarsItem2
            pillarsItem1
            pageTitle
            node_locale
            name
            metaTitle
            kpi4ShortDescription
            kpi4Number
            kpi3ShortDescription
            kpi3Number
            kpi2ShortDescription
            kpi2Number
            metaDescription {
              metaDescription
            }
            kpi1ShortDescription
            kpi1Number
            homepageTitle
            homepageItem3Title
            homepageItem3ShortDescription {
              homepageItem3ShortDescription
            }
            homepageItem3Image {
              gatsbyImageData(placeholder: BLURRED)
              description
            }
            homepageItem2Title
            homepageItem2ShortDescription {
              homepageItem2ShortDescription
            }
            homepageItem2Image {
              gatsbyImageData(placeholder: BLURRED)
              description
            }
            homepageItem1Title
            homepageItem1ShortDescription {
              homepageItem1ShortDescription
            }
            homepageItem1Image {
              gatsbyImageData(placeholder: BLURRED)
              description
              title
            }
            childContentfulAboutUsHomepageItem3ShortDescriptionTextNode {
              homepageItem3ShortDescription
            }
            childContentfulAboutUsHomepageItem2ShortDescriptionTextNode {
              homepageItem2ShortDescription
            }
            childContentfulAboutUsHomepageItem1ShortDescriptionTextNode {
              homepageItem1ShortDescription
            }
          }
        }
      }
    }
    allContentfulInsight(
      filter: {
        country3: {elemMatch: {technicalName: {eq: $technicalName}}}
        node_locale: {eq: $nodeLocale}
      }
      limit: 10
      sort: {publicationDate: DESC}
    ) {
      edges {
        node {
          ...insightFields
          metaDescription {
            metaDescription
          }
          image {
            gatsbyImageData(placeholder: BLURRED)
            description
            file {
              url
              contentType
            }
          }
        }
      }
    }
    searchInsights: allContentfulInsight(
      filter: {
        country3: {elemMatch: {technicalName: {eq: $technicalName}}}
        node_locale: {eq: $nodeLocale}
      }
      limit: 3
      sort: {publicationDate: DESC}
    ) {
      edges {
        node {
          ...insightFields
          metaDescription {
            metaDescription
          }
          image {
            gatsbyImageData(width: 250, height: 150, placeholder: BLURRED)
            description
            file {
              url
              contentType
            }
          }
        }
      }
    }
    allContentfulLocations(
      filter: {
        slug: {regex: "/^((?!-kls).)*$/"}
        node_locale: {eq: $nodeLocale}
      }
    ) {
      edges {
        node {
          locations {
            ...locationFields
          }
        }
      }
    }
    allContentfulContinent(filter: {node_locale: {eq: $nodeLocale}}) {
      edges {
        node {
          ...continentFields
        }
      }
    }
    allContentfulMenuItem {
      edges {
        node {
          menuItemPerCountry {
            link
            name
            title
            countries {
              urlPrefix
              technicalName
            }
          }
        }
      }
    }
    contentfulCountry(
      node_locale: {eq: $nodeLocale}
      technicalName: {eq: $technicalName}
    ) {
      ...countryDataFields
      id
      name
      carousel {
        title
        description {
          description
        }
        buttonLabel
        buttonLink
        imageOrVideo {
          gatsbyImageData(placeholder: BLURRED)
          file {
            url
            contentType
          }
          description
        }
      }
      ...partnerFields
      services {
        topLevelServices {
          entryId
          subLevelServices {
            entryId
          }
        }
      }
    }
    allContentfulService(filter: {node_locale: {eq: $nodeLocale}}) {
      edges {
        node {
          ...serviceFields
        }
      }
    }
    allContentfulKeyPlay(
      limit: 6
      filter: {
        node_locale: {eq: $nodeLocale}
        countriesOfDestination: {
          elemMatch: {technicalName: {eq: $technicalName}}
        }
      }
    ) {
      edges {
        node {
          contentful_id
          id
          title {
            title
          }
          slug
          image {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
    allContentfulPlaybook(filter: {node_locale: {eq: $nodeLocale}}) {
      nodes {
        partnersSubtitlesHomepage
        partnersSurtitleHomepage
        partnersTitle
      }
    }
    contentfulHeader(name: {eq: "Header"}) {
      logo {
        title
        file {
          url
          fileName
          contentType
        }
      }
    }
    careerLink: contentfulMenuItem(name: {eq: "Careers-KM"}) {
      link
    }
    siteMetaData: site {
      ...SiteMetadata
    }
    contentfulGenericData {
      genericKeywords {
        content
      }
    }
  }
`
